import React, { useImperativeHandle, useRef } from 'react'
import Select, { components as vendorComponents } from 'react-select'

import { MenuWithFilter } from '@components/Form/components/Select/components/MenuWithFilter'
import { FocusContext } from '@components/Form/components/Select/hooks/use-focus-context'
import type { FocusSharedProps } from '@components/Form/components/Select/hooks/use-focus-manager'
import { useFocusManager } from '@components/Form/components/Select/hooks/use-focus-manager'
import type { AutomapValues } from '@components/Form/components/Select/utils/automap-values'
import { automapValues } from '@components/Form/components/Select/utils/automap-values'
import { convertVariantsToClassnames } from '@components/Form/components/Select/utils/convert-variants-to-classnames'
import { createSensiblePropDefaults } from '@components/Form/components/Select/utils/create-sensible-prop-defaults'
import { DropdownIndicator } from '@components/OverflowMultiSelect/components/DropdownIndicator.tsx'
import { LoadingIndicator } from '@components/OverflowMultiSelect/components/LoadingIndicator.tsx'
import { LoadingValueContainer } from '@components/OverflowMultiSelect/components/LoadingValueContainer.tsx'
import { Option } from '@components/OverflowMultiSelect/components/Option'
import { Placeholder } from '@components/OverflowMultiSelect/components/Placeholder'
import { SingleValue } from '@components/OverflowMultiSelect/components/SingleValue'
import { sortOptions } from '@components/OverflowMultiSelect/hooks/sortOptions'
import { useEdgeAwareDropdown } from '@components/OverflowMultiSelect/hooks/useEdgeAwareDropdown'

import { variants } from '../misc/variants'

import type { Variants } from '../misc/variants'
import type { ForwardedRef, ReactElement } from 'react'
import type {
  GroupBase,
  SelectInstance,
  Props as SelectProps,
} from 'react-select'

type SharedSelectProps = FocusSharedProps &
  Variants & {
    readonly isDate?: boolean
    readonly svg?: ReactElement
    readonly title?: string
    readonly onClickOutside?: () => void
    readonly hideActiveValues?: boolean
    readonly invalid?: boolean
    readonly hideBadge?: boolean
    readonly hideFilter?: boolean
    // Duplicate the isDisabled prop to avoid remapping the field
    readonly disabled?: boolean
    readonly transformOutput?: boolean
  }

function InnerGenericSelect<
  Option extends {
    value: unknown
    label: string
    subtitle?: string
    image?: string | undefined
  },
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
  IsMapped extends boolean = false,
  OptionValue = unknown,
>(
  props: AutomapValues<OptionValue, Option, IsMulti, IsMapped, Group> &
    Omit<
      SelectProps<Option, IsMulti, Group>,
      'isMulti' | 'onChange' | 'options' | 'value'
    > &
    SharedSelectProps,
  forwardedRef: ForwardedRef<SelectInstance<Option, IsMulti, Group>>,
) {
  type Instance = SelectInstance<Option, IsMulti, Group>

  const {
    classNames,
    components,
    disabled,
    hideBadge,
    hideFilter,
    intent,
    invalid,
    mapValuesToOptions,
    onChange,
    onClickOutside,
    options,
    styles,
    transformOutput,
    value,
    ...rest
  } = props

  const { isDisabled, isMulti, onMenuClose, tooltip } = {
    ...rest,
    isDisabled: props.isDisabled ?? disabled,
  }

  const mapperProps = automapValues({
    value,
    isMulti,
    mapValuesToOptions,
    onChange,
    options,
  })

  if (!transformOutput) {
    mapperProps.onChange = props.onChange
  }

  const sortOptionProps = sortOptions(mapperProps.value, options)

  const ref = useRef<Instance>(null)
  useImperativeHandle(forwardedRef, () => ref.current as Instance)

  const computedClassNames = convertVariantsToClassnames<
    Option,
    IsMulti,
    Group
  >(variants({ intent, isDisabled }), classNames)

  const edgeAwareProps = useEdgeAwareDropdown(ref, styles, computedClassNames)
  const { props: focusProps, setIsFocused } = useFocusManager({
    ref,
    onClickOutside,
    onMenuClose,
  })

  const { components: defaultComponents, ...defaults } =
    createSensiblePropDefaults(isMulti)
  return (
    <FocusContext.Provider value={setIsFocused}>
      <Select<Option, IsMulti, Group>
        {...defaults}
        {...rest}
        components={{
          ...defaultComponents,
          Option,
          Placeholder:
            intent === 'inline'
              ? tooltip
                ? Placeholder
                : vendorComponents.Placeholder
              : () => null,
          ValueContainer: LoadingValueContainer,
          SingleValue: hideBadge ? vendorComponents.SingleValue : SingleValue,
          DropdownIndicator,
          Menu: hideFilter ? vendorComponents.Menu : MenuWithFilter,
          LoadingIndicator,
          ...components,
        }}
        {...mapperProps}
        {...edgeAwareProps}
        {...focusProps}
        {...sortOptionProps}
        isDisabled={isDisabled}
        ref={ref}
      />
    </FocusContext.Provider>
  )
}

export const GenericSelect = React.forwardRef(InnerGenericSelect)
