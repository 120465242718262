import { NoOptionsMessage } from '@components/Form/components/Select/components/NoOptionsMessage'

import type { GroupBase, Props } from 'react-select'

export function createSensiblePropDefaults<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(isMulti?: IsMulti) {
  return {
    backspaceRemovesValue: false,
    blurInputOnSelect: false,
    hideSelectedOptions: false,
    closeMenuOnSelect: !isMulti,
    isSearchable: false,
    menuPlacement: 'auto',
    placeholder: 'Select...',
    components: {
      NoOptionsMessage,
    },
  } satisfies Props<Option, IsMulti, Group>
}
