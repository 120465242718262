import { components } from 'react-select'

import { ValueContainer } from '@components/OverflowMultiSelect/components/ValueContainer.tsx'
import { Skeleton } from '@components/Skeleton/Skeleton.tsx'

import type { ValueContainerProps, GroupBase } from 'react-select'

export function LoadingValueContainer<
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: ValueContainerProps<Option, IsMulti, Group>) {
  if (props.selectProps.isLoading) {
    return (
      <components.ValueContainer {...props}>
        <Skeleton className="tw-w-1/2" inline />
      </components.ValueContainer>
    )
  }

  if (props.isMulti) {
    return <ValueContainer {...props} />
  }

  return <components.ValueContainer {...props} />
}
