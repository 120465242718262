// @ts-nocheck
import { difference, intersection } from 'lodash'

import { isOptionGroup } from '../components/MultiSelect'

import type {
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  PropsValue,
} from 'react-select'

export function sortOptions<Option>(
  value: PropsValue<Option> | undefined,
  options: OptionsOrGroups<Option, GroupBase<Option>> | undefined,
) {
  if (!options?.length) {
    return { options: [] }
  }

  if (!Array.isArray(value) || value.length === 0) {
    return { options }
  }

  return { options: sortRecursivelyByActive(value, options) }
}

function sortRecursivelyByActive<
  Option,
  T extends OptionsOrGroups<Option, GroupBase<Option>>,
>(activeOptions: MultiValue<Option>, allOptions: T): T {
  const options = [
    ...intersection(activeOptions, allOptions),
    ...difference(allOptions, activeOptions),
  ]
  return options.map((group) => {
    if (isOptionGroup(group)) {
      return {
        ...group,
        options: sortRecursivelyByActive(activeOptions, group.options),
      }
    }

    return group
  })
}
