// @ts-nocheck
import type { ClassNamesConfig, GroupBase } from 'react-select'
import { mapValues } from 'lodash'
import { mergeClassNames } from '@components/Form/components/Select/utils/merge-class-names'

export function convertVariantsToClassnames<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(variants, classNames?): ClassNamesConfig<Option, IsMulti, Group> {
  const baseClassNames = mapValues(variants, (item) => {
    return () => {
      return item()
    }
  })
  return mergeClassNames(baseClassNames, classNames)
}
