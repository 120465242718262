import * as ReactPopover from '@radix-ui/react-popover'
import cn from 'classnames'
import { useState } from 'react'

import type { ComponentPropsWithoutRef } from 'react'

type Props = Pick<ReactPopover.PopoverContentProps, 'children' | 'className'> &
  Pick<ReactPopover.PopoverProps, 'open'> & {
    readonly trigger: ({
      onClick,
      onMouseOut,
      onMouseOver,
    }: Pick<
      ComponentPropsWithoutRef<'button'>,
      'onClick' | 'onMouseOut' | 'onMouseOver'
    >) => ReactPopover.PopoverTriggerProps['children']
  }

export function Popover({ children, className, trigger }: Props) {
  const [open, setOpen] = useState(false)
  const classes = cn(
    className,
    'tw-rounded focus-visible:tw-outline-none  tw-bg-white tw-shadow  tw-will-change-[transform,opacity] data-[state=open]:data-[side=top]:tw-animate-slideDownAndFade data-[state=open]:data-[side=right]:tw-animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:tw-animate-slideUpAndFade data-[state=open]:data-[side=left]:tw-animate-slideRightAndFade',
  )

  return (
    <ReactPopover.Root open={open}>
      <ReactPopover.Trigger asChild>
        {trigger({
          onMouseOver() {
            setOpen(true)
          },
          onMouseOut() {
            setOpen(false)
          },
          onClick(e) {
            setOpen(true)
          },
        })}
      </ReactPopover.Trigger>
      <ReactPopover.Portal>
        <ReactPopover.Content className={classes}>
          {children}
        </ReactPopover.Content>
      </ReactPopover.Portal>
    </ReactPopover.Root>
  )
}
