import classNames from 'classnames'
import { isFunction } from 'lodash'
import { isElement, isValidElementType } from 'react-is'
import { twMerge } from 'tailwind-merge'

import { isOptionWithBackgroundColor, isOptionWithImages } from './MultiSelect'

import EmployeeBadge from '../../EmployeeBadge'

import type { OptionUnion, OptionWithImages } from './MultiSelect'
import type { HTMLProps, ReactNode } from 'react'
import type { UnionToIntersection } from 'type-fest'

type Props<T = OptionUnion> = Pick<
  HTMLProps<HTMLElement>,
  'children' | 'className' | 'onClick' | 'onMouseEnter' | 'onMouseLeave'
> & {
  readonly item: T
  readonly show?: {
    [K in keyof Partial<UnionToIntersection<T>>]: boolean
  }
  readonly truncate?: boolean
  readonly components?: {
    Image?: typeof Image
    Item?: typeof Item
    ContentWrapper?: typeof ContentWrapper
    Before?: (props: Props) => JSX.Element
  }
}

export function ContentWrapper({ children, className, item, show }: Props) {
  return (
    <span
      className={classNames(className, 'tw-whitespace-nowrap', {
        "tw-text-xs tw-font-bold tw-font-['Proxima_Nova_SemiBold'] tw-text-white tw-p-1 tw-leading-none tw-rounded":
          isOptionWithBackgroundColor(item),
      })}
      style={{
        backgroundColor: isOptionWithBackgroundColor(item)
          ? item.backgroundColor
          : undefined,
      }}
      title={item.label}
    >
      {children}
    </span>
  )
}

export function Image({
  item,
  size,
}: Props<OptionWithImages> & {
  readonly size: 'large' | 'medium' | 'small'
}) {
  const [firstName, lastName] = item.label.split(' ')
  const initials = `${firstName?.[0]?.toUpperCase()}${lastName?.[0]?.toUpperCase() ?? ''}`

  if (!isElement(item.image) && item.image?.startsWith('#')) {
    return (
      <span
        className={classNames(
          {
            'tw-w-[18px] tw-h-[18px] tw-text-xs': size === 'small',
            'tw-w-[24px] tw-h-[24px] tw-text-xs': size === 'medium',
            'tw-w-[35px] tw-h-[35px] tw-text-base': size === 'large',
          },
          'tw-rounded tw-text-white tw-p-2 tw-flex tw-items-center tw-justify-center [text-shadow:0_0_6px_#000000] tw-border-gray-200 tw-border-solid tw-border',
        )}
        style={{
          backgroundColor: item.image,
        }}
      >
        {initials}
      </span>
    )
  }

  return (
    <EmployeeBadge
      avatarSrc={item.image}
      firstName={item.label.split(' ')[0]}
      lastName={item.label.split(' ')[1]}
      onlyAvatar
      {...{ [size]: true }}
    />
  )
}

export function Item({ onClick, onMouseEnter, onMouseLeave, ...props }: Props) {
  const { className, components = {}, item, show = {}, truncate } = props
  const AsImage = components.Image ?? Image
  const AsContentWrapper = components.ContentWrapper ?? ContentWrapper
  return (
    <span
      className={twMerge(
        'tw-flex tw-items-center tw-space-x-2',
        className,
        truncate && 'tw-overflow-hidden',
      )}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {components.Before && <components.Before {...props} />}

      {show?.image !== false && isOptionWithImages(item) && (
        <AsImage item={item} size="medium" />
      )}
      <AsContentWrapper
        {...props}
        className={twMerge('tw-text-gray-900', truncate && 'tw-truncate')}
      >
        {item.label}
        {show?.subtitle !== false && item.subtitle && (
          <span
            className={`
            tw-block tw-text-sm tw-font-normal tw-text-primary-grey
          `}
          >
            @{item.subtitle}
          </span>
        )}
      </AsContentWrapper>
    </span>
  )
}

export function GenericListItem(props: Props) {
  const { components = {} } = props
  const ListItem = components.Item ?? Item
  return <ListItem {...props} />
}

export function ListItem(
  props: Omit<Props, 'item'> & {
    readonly label: ReactNode | string
    readonly subtitle?: string
    readonly image?: string | undefined
    readonly duplicateUrl?: string | undefined
  },
) {
  const { image, label, subtitle } = props
  return (
    <GenericListItem item={{ label, subtitle, image, value: '' }} {...props} />
  )
}

export default { GenericListItem, Item, ContentWrapper, EmployeeBadge, Image }
