import { tv } from '@utils/tv'

import type { VariantProps } from 'tailwind-variants'

export const variants = tv({
  slots: {
    control:
      'tw-rounded tw-cursor-pointer  tw-border-0 tw-border-solid tw-shadow-none',
    valueContainer: '',
    dropdownIndicator: '',
    indicatorSeparator: '',
    placeholder: 'tw-text-red',
    singleValue: 'tw-ml-0',
    container: '',
    noOptionsMessage: 'tw-text-sm',
    multiValue: 'tw-hidden',
    menu: 'tw-mt-1 tw-z-[51]',
    groupHeading: 'tw-text-sm',
    menuList: 'tw-scrollbar tw-scrollbar-thumb-primary-blue tw-scrollbar-w-1',
    group:
      'tw-border-b-primary-table-border-color tw-border-solid tw-border-b last:tw-border-b-0',
  },
  variants: {
    intent: {
      inline: {
        control: 'tw-min-h-10 tw-rounded-none tw-border-b tw-bg-transparent',
        valueContainer: 'tw-pl-0',
        dropdownIndicator: 'tw-p-1 tw-pr-0',
        indicatorSeparator: 'tw-hidden',
      },
      filter: {
        control: 'tw-border-none tw-p-2 tw-h-12 tw-shadow',
      },
      ui: {
        control: 'tw-min-h-5 tw-h-full    tw-p-0 tw-drop-shadow-md',
      },
    },

    isDisabled: {
      true: {
        container: 'tw-pointer-events-auto  tw-opacity-60',
        control: 'tw-cursor-not-allowed',
      },
    },
  },
  defaultVariants: {
    intent: 'filter',
    invalid: false,
  },
})

export type Variants = VariantProps<typeof variants>
