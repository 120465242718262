import { mergeWith } from 'lodash'
import { twMerge } from 'tailwind-merge'

import type { GroupBase } from 'react-select'
import type { Props as SelectProps } from 'react-select/dist/declarations/src'
import type { StylesProps } from 'react-select/dist/declarations/src/styles'

type ClassNames<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = SelectProps<Option, IsMulti, Group>['classNames']

type Styles<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = (
  props: StylesProps<Option, IsMulti, Group>[keyof StylesProps<
    Option,
    IsMulti,
    Group
  >],
) => string

export function mergeClassNames<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  baseClassNames: ClassNames<Option, IsMulti, Group>,
  classNames: ClassNames<Option, IsMulti, Group>,
) {
  return classNames
    ? mergeWith(
        {},
        baseClassNames,
        classNames,
        (
          objectValue: Styles<Option, IsMulti, Group>,
          sourceValue: Styles<Option, IsMulti, Group>,
        ): Styles<Option, IsMulti, Group> => {
          if (objectValue && sourceValue) {
            return (props) => twMerge(sourceValue(props), objectValue(props))
          }
          return objectValue || sourceValue
        },
      )
    : baseClassNames
}
