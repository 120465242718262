import type {
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  SingleValue,
  PropsValue,
} from 'react-select'
import type { ActionMeta } from 'react-select/dist/declarations/src/types'

export type AutomapValues<
  OptionValue,
  Option,
  IsMulti extends boolean = false,
  IsMapped extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = {
  isMulti?: IsMulti
  mapValuesToOptions?: IsMapped
  options: OptionsOrGroups<Option, Group> | undefined
  value?: IsMapped extends true
    ? IsMulti extends true
      ? MultiValue<OptionValue>
      : SingleValue<OptionValue>
    : IsMulti extends true
      ? MultiValue<Option extends infer A ? A : 'fuck'>
      : SingleValue<Option extends infer A ? A : 'fuck'>
  onChange?: IsMapped extends true
    ? IsMulti extends true
      ? MultiOnChange<OptionValue, Option>
      : SingleOnChange<OptionValue, Option>
    : (
        newValue: IsMulti extends true
          ? MultiValue<Option>
          : SingleValue<Option>,
        actionMeta: ActionMeta<Option>,
      ) => void
}

export function automapValues<
  OptionValue,
  Option,
  IsMulti extends boolean = false,
  IsMapped extends boolean = false,
>({
  isMulti,
  mapValuesToOptions,
  onChange,
  options,
  value,
}: AutomapValues<OptionValue, Option, IsMulti, IsMapped>) {
  return mapValuesToOptions
    ? isMulti
      ? mapMulti({
          value: value as MultiValue<OptionValue>,
          options,
          onChange: onChange as MultiOnChange<OptionValue, Option>,
        })
      : mapSingle({
          value: value as SingleValue<OptionValue>,
          options,
          onChange: onChange as SingleOnChange<OptionValue, Option>,
        })
    : {
        value: value as PropsValue<Option>,
        onChange,
      }
}

function isOption<OptionValue, Option>(
  option: GroupBase<Option> | Option,
): option is Option extends { value: OptionValue } ? Option : never {
  if (!option) {
    return false
  }

  return typeof option === 'object' && 'value' in option
}

type SingleOnChange<OptionValue, Option> =
  | ((
      newValue: SingleValue<OptionValue>,
      actionMeta: ActionMeta<Option>,
      newUnmappedValue: SingleValue<Option>,
    ) => void)
  | undefined

type StandardSingleOnChange<Option> =
  | ((newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>) => void)
  | undefined

type StandardMultiOnChange<Option> =
  | ((newValue: MultiValue<Option>, actionMeta: ActionMeta<Option>) => void)
  | undefined

type MultiOnChange<OptionValue, Option> =
  | ((
      newValue: MultiValue<OptionValue>,
      actionMeta: ActionMeta<Option>,
      newUnmappedValue: MultiValue<Option>,
    ) => void)
  | undefined

function mapSingle<OptionValue, Option>({
  onChange,
  options,
  value,
}: {
  options: OptionsOrGroups<Option, GroupBase<Option>> | undefined
  value: SingleValue<OptionValue>
  onChange: SingleOnChange<OptionValue, Option>
}): {
  value: SingleValue<Option>
  onChange: StandardSingleOnChange<
    Option extends { value: OptionValue } ? Option : never
  >
} {
  return {
    value:
      !options || (!value && value !== 0) || options.length === 0
        ? null
        : options.find((optionOrGroup): optionOrGroup is Option =>
            isOption(optionOrGroup) ? optionOrGroup.value === value : false,
          ) ?? null,
    onChange: onChange
      ? (value, actionMeta) => {
          onChange(value?.value ?? null, actionMeta, value)
        }
      : undefined,
  }
}

function mapMulti<OptionValue, Option>({
  onChange,
  options,
  value,
}: {
  options: OptionsOrGroups<Option, GroupBase<Option>> | undefined
  value: MultiValue<OptionValue>
  onChange: MultiOnChange<OptionValue, Option>
}): {
  value: MultiValue<Option>
  onChange: StandardMultiOnChange<
    Option extends { value: OptionValue } ? Option : never
  >
} {
  return {
    value:
      !options || !value || options.length === 0 || value.length === 0
        ? []
        : options.filter<Option>((option): option is Option =>
            isOption(option)
              ? value.includes(option.value as OptionValue) || false
              : false,
          ),
    onChange: onChange
      ? (value, actionMeta) => {
          onChange(
            value.map((item) => item.value),
            actionMeta,
            value,
          )
        }
      : undefined,
  }
}
