import cn from 'classnames'
import { format } from 'date-fns'
import React, { useMemo, useRef } from 'react'
import { components } from 'react-select'

import { ListItem } from './GenericListItem'

import { OverflowList } from '../../OverflowList'
import { OverflowListPopover } from '../../OverflowList/OverflowListPopover'
import { useAutoCompact } from '../hooks/useAutoCompact'

import type { GenericOption, MultiSelect, OptionGroup } from './MultiSelect'
import type { ComponentProps } from 'react'
import type { ValueContainerProps } from 'react-select'

export function ValueContainer<Option extends GenericOption>({
  children,
  className,
  ...props
}: ValueContainerProps<Option, true, OptionGroup<Option>> & {
  readonly selectProps: Pick<
    ComponentProps<typeof MultiSelect<Option>>,
    'hideActiveValues' | 'isDate' | 'svg'
  >
}) {
  const value = props.getValue()
  const {
    selectProps: { isDate, menuIsOpen, svg },
  } = props
  const labelRef = useRef<HTMLSpanElement>(null)
  const { autoCompact } = useAutoCompact(labelRef)

  const hideActiveValues =
    !isDate && (autoCompact || props.selectProps.hideActiveValues)
  const Overflow = useMemo(() => {
    if (isDate) {
      const [startDate, endDate] = value
      const label =
        startDate && endDate
          ? `${format(startDate.value as Date, 'dd.MM.yyyy')} - ${format(endDate.value as Date, 'dd.MM.yyyy')}`
          : undefined
      return (
        label && <ListItem label={label} show={{ image: false }} truncate />
      )
    }

    return <OverflowList className="tw-flex" items={value as any} />
  }, [isDate ? value : value.length, isDate])

  return (
    <components.ValueContainer
      {...props}
      className={cn(
        className,
        'tw-flex tw-flex-nowrap tw-pr-0 tw-text-base tw-text-primary-darkgrey',
      )}
    >
      {props.selectProps.title && (
        <span className="tw-mr-2" ref={labelRef}>
          {props.selectProps.title}
          {!hideActiveValues &&
          value.length > 0 &&
          (!isDate || (isDate && value.length === 2))
            ? ':'
            : ''}
        </span>
      )}
      {!hideActiveValues && Overflow}

      {
        // @ts-expect-error
        hideActiveValues && value.length > 0 && OverflowListPopover(value)
      }
      <div>{children}</div>
    </components.ValueContainer>
  )
}
