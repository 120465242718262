import { Popover } from '../Popover'
import GenericListItem, { Image, Item } from '../OverflowMultiSelect/components/GenericListItem'
import { OptionUnion } from '../OverflowMultiSelect/components/MultiSelect'

const ImageComponent: typeof Image = ({ ...props }) => <Image {...props} size={'large'} />
// @ts-ignore
const ItemComponent: typeof Item = ({ ...props }) => <Item {...props} truncate />

export const OverflowListPopover = (list: Array<OptionUnion>) => {
  return (
    <Popover
      className={'tw-divide-y tw-text-base tw-divide-primary-table-border-color tw-divide-solid tw-w-[250px]'}
      trigger={({ onMouseOver, onMouseOut, onClick }) => (
        <div>
          <button
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onClick={onClick}
            className="tw-bg-primary-green tw-text-sm tw-text-white tw-w-6 tw-h-6 tw-rounded-full tw-inline-flex tw-items-center tw-justify-center tw-outline-none tw-font-['Proxima_Nova_SemiBold']"
          >
            +{list.length}
          </button>
        </div>
      )}
    >
      {list.map((item, key) => (
        <GenericListItem.GenericListItem
          className={'tw-relative tw-flex tw-items-center tw-py-2 tw-px-3 tw-space-x-3'}
          item={item}
          key={key}
          components={{
            Image: ImageComponent,
            Item: ItemComponent,
          }}
        />
      ))}
    </Popover>
  )
}
