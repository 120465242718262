// @ts-nocheck
import cx from 'classnames'

import { GenericSelect } from '@components/Form/components/Select/components/GenericSelect'

import type { ComponentProps, ReactElement } from 'react'
import type { GroupBase, MultiValue as SelectMultiValue } from 'react-select'

export interface GenericOption<T = unknown> {
  label: string
  value: T
}

export interface BaseOption {
  label: string
  value: string
  subtitle?: string | undefined
}

export interface OptionWithImages extends BaseOption {
  image: string | undefined
}

export interface OptionWithBackgrounds extends BaseOption {
  backgroundColor: string | undefined
}

export type OptionUnion = BaseOption | OptionWithBackgrounds | OptionWithImages

export const isOptionWithImages = (
  props: OptionUnion,
): props is OptionWithImages => 'image' in props

export const isOptionWithValue = (
  option: object | unknown,
): option is { value: unknown } => 'image' in option

export const getOptionValue = (option: object | unknown) => {
  if (!isOptionWithValue(option)) {
    throw new Error('Unmappable option')
  }

  return option.value
}

export const isOptionWithBackgroundColor = (
  props: OptionUnion,
): props is OptionWithBackgrounds => 'backgroundColor' in props

export function isOptionGroup<T>(
  optionOrGroup: GroupBase<T> | T,
): optionOrGroup is GroupBase<T> {
  return (
    optionOrGroup &&
    typeof optionOrGroup === 'object' &&
    'options' in optionOrGroup
  )
}

export type CurrentOption<
  WithImages = false,
  WithBackgrounds = false,
> = WithImages extends true
  ? OptionWithImages
  : WithBackgrounds extends true
    ? OptionWithBackgrounds
    : BaseOption

export type SharedSelectProps<Option extends GenericOption> = {
  readonly isDate?: boolean
  readonly svg?: ReactElement
  readonly title?: string
  readonly onClickOutside?: () => void
  readonly hideActiveValues?: boolean
} & (
  | { mapValuesToOptions: true; value?: Array<number | string> }
  | { mapValuesToOptions?: false; value?: SelectMultiValue<Option> }
)

type Props<
  Option extends GenericOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Pick<
  ComponentProps<typeof GenericSelect<Option, IsMulti, Group>>,
  | 'className'
  | 'classNames'
  | 'components'
  | 'filterOption'
  | 'inputValue'
  | 'intent'
  | 'isClearable'
  | 'isOptionDisabled'
  | 'onChange'
  | 'onInputChange'
  | 'options'
> &
  SharedSelectProps<Option>

export type OptionGroup<Option extends GenericOption> = GroupBase<Option>

export function MultiSelect<Option extends GenericOption = GenericOption>({
  className,
  ...rest
}: Props<Option, true, OptionGroup<Option>>) {
  return (
    <GenericSelect
      className={cx('tw-w-full tw-border-none', className)}
      isMulti
      {...rest}
    />
  )
}
