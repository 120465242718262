import { components, type GroupBase, type NoticeProps } from 'react-select'

export function NoOptionsMessage<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: NoticeProps<Option, IsMulti, Group>) {
  return (
    <components.NoOptionsMessage {...props}>
      {Translator.trans('project.no-results')}
    </components.NoOptionsMessage>
  )
}
