import { components } from 'react-select'

import type { DropdownIndicatorProps } from 'react-select'

export function DropdownIndicator({
  ...props
}: DropdownIndicatorProps<Date, false, never>) {
  return props.selectProps.isLoading ? null : (
    <components.DropdownIndicator {...props} />
  )
}
