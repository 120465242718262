import { useRef, useState } from 'react'

import { mergeClassNames } from '@components/Form/components/Select/utils/merge-class-names'
import { mergeStyles } from '@components/Form/components/Select/utils/merge-styles'

import type { ForwardedRef } from 'react'
import type {
  ClassNamesConfig,
  GroupBase,
  SelectInstance,
  StylesConfig,
} from 'react-select'

export function useEdgeAwareDropdown<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  selectRef: ForwardedRef<SelectInstance<Option, IsMulti, Group> | null> | null,
  styles?: StylesConfig<Option, IsMulti, Group>,
  classNames?: ClassNamesConfig<Option, IsMulti, Group>,
) {
  const [alignRight, setAlignRight] = useState<number>()
  const menuObserver = useRef<IntersectionObserver>()

  const onMenuOpen = () => {
    if (alignRight !== undefined) {
      return
    }

    setTimeout(() => {
      menuObserver.current = new IntersectionObserver((entries = []) => {
        if (!entries || !entries[0]) {
          return
        }

        const { boundingClientRect, intersectionRatio, intersectionRect } =
          entries[0]

        if (intersectionRatio === 0) {
          return
        }

        setAlignRight(
          boundingClientRect.width > intersectionRect.width
            ? intersectionRect.width - boundingClientRect.width - 10
            : 0,
        )
      })

      if (
        selectRef &&
        'current' in selectRef &&
        selectRef.current !== undefined
      ) {
        const sibling = selectRef.current?.controlRef?.nextSibling
        if (sibling) {
          menuObserver.current.observe(sibling as any)
        }
      }
    }, 1)
  }

  const onMenuClose = () => {
    // setAlignRight(0)
    menuObserver.current?.disconnect()
  }

  return {
    styles: mergeStyles(styles, {
      menu: (orig) => ({
        ...orig,
        left: alignRight ?? 0,
      }),
    }),
    classNames: mergeClassNames(classNames, {
      menu: () => 'tw-transition-[left] tw-ease-in-out tw-duration-500',
    }),
    onMenuOpen,
    onMenuClose,
  }
}
