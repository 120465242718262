import * as Checkbox from '@radix-ui/react-checkbox'
import { CheckIcon } from '@radix-ui/react-icons'
import { components } from 'react-select'

import { twMerge } from '@utils/cn.ts'

import {
  GenericListItem,
  ContentWrapper as ParentContentWrapper,
} from './GenericListItem'

import type { ComponentProps, ReactNode } from 'react'
import type { GroupBase, OptionProps } from 'react-select'

const classes = `
  tw-flex tw-size-[15px] tw-shrink-0 tw-appearance-none tw-items-center
  tw-justify-center tw-border tw-border-solid tw-border-primary-lightgrey
  tw-outline-none

  data-[state=checked]:tw-border-primary-blue

  hover:tw-border-primary-blue
`
const ContentWrapper: typeof ParentContentWrapper = ({
  className,
  ...props
}) => (
  <ParentContentWrapper
    {...props}
    className={twMerge(className, `tw-text-current`)}
  />
)
export function OptionWrapper<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  checkbox,
  className,
  labelProps,
  ...props
}: OptionProps<Option, IsMulti, Group> & {
  readonly labelProps?: ComponentProps<typeof GenericListItem>
  readonly checkbox?: (props: { className: string }) => ReactNode
}) {
  const { isMulti } = props
  return (
    <components.Option
      {...props}
      className={twMerge(
        className,
        props.isSelected && 'tw-bg-white',
        !props.isMulti && props.isSelected && 'tw-bg-primary-blue',
        'tw-flex tw-cursor-pointer tw-items-center tw-space-x-3',
      )}
    >
      {isMulti &&
        (checkbox ? (
          checkbox({ className: classes })
        ) : (
          <Checkbox.Root
            checked={props.isSelected}
            className={classes}
            disabled={props.isDisabled}
          >
            <Checkbox.Indicator className="tw-text-primary-blue">
              <CheckIcon />
            </Checkbox.Indicator>
          </Checkbox.Root>
        ))}
      <GenericListItem
        className={twMerge('tw-text-sm')}
        components={
          !props.isMulti && props.isSelected ? { ContentWrapper } : {}
        }
        item={props.data}
        truncate
        {...labelProps}
      />
    </components.Option>
  )
}
