import cn from 'classnames'
import { isElement } from 'react-is'

import { twMerge } from '@utils/cn.ts'

import styles from './EmployeeBadge.module.css'

import type { FC } from 'react'

interface IEmployeeBadge {
  readonly avatarSrc?: string | null
  readonly onlyAvatar?: boolean
  readonly firstName: string
  readonly initials?: string | null
  readonly lastName: string
  readonly nickname?: string | null
  readonly small?: boolean
  readonly medium?: boolean
  readonly large?: boolean
  readonly alignLeft?: boolean
}

const EmployeeBadge: FC<IEmployeeBadge> = ({
  alignLeft = false,
  avatarSrc: avatarSource = null,
  firstName = 'John',
  initials = null,
  large = false,
  lastName = 'Smith',
  medium = false,
  nickname = null,
  onlyAvatar = false,
  small = false,
}) => {
  const _initials = initials
    ? initials
    : `${firstName?.[0]?.toUpperCase()}${lastName?.[0]?.toUpperCase()}`

  return (
    <div
      className={cn(
        styles.EmployeeBadge,
        {
          [styles.Small]: small,
          [styles.Medium]: medium,
          [styles.AlignLeft]: alignLeft,
          [styles.Large]: large,
        },
        'tw-truncate',
      )}
    >
      <div
        className={cn(styles.EmployeeBadge__AvatarBox, {
          [styles.Small]: small,
          [styles.Medium]: medium,
          [styles.Large]: large,
        })}
      >
        {avatarSource ? (
          isElement(avatarSource) ? (
            avatarSource
          ) : (
            <img alt={_initials} src={avatarSource} />
          )
        ) : (
          _initials
        )}
      </div>
      {!onlyAvatar && (
        <div
          className={cn(
            styles.EmployeeBadge__Info,
            {
              [styles.Small]: small,
              [styles.Medium]: medium,
              [styles.Large]: large,
            },
            'tw-truncate',
          )}
        >
          <div
            className={twMerge(
              styles.EmployeeBadge__FullName,
              `tw-truncate tw-whitespace-nowrap`,
            )}
          >
            {firstName} {lastName}
          </div>
          {!small && (
            <div
              className={twMerge(styles.EmployeeBadge__NickName, 'tw-truncate')}
            >
              @{nickname ? nickname : firstName}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default EmployeeBadge
