// @ts-nocheck
import { mergeWith } from 'lodash'

import type { GroupBase, StylesConfig } from 'react-select'

export function mergeStyles<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  baseClassNames?: StylesConfig<Option, IsMulti, Group>,
  classNames?: StylesConfig<Option, IsMulti, Group>,
) {
  if (!baseClassNames || !classNames) {
    return baseClassNames || classNames
  }

  return mergeWith(
    {},
    baseClassNames,
    classNames,
    (
      objectValue: StylesConfig<Option, IsMulti, Group>,
      sourceValue: StylesConfig<Option, IsMulti, Group>,
    ): StylesConfig<Option, IsMulti, Group> => {
      if (objectValue && sourceValue) {
        return (props) => {
          return {
            ...sourceValue(props),
            ...objectValue(props),
          }
        }
      }
      return objectValue || sourceValue
    },
  )
}
