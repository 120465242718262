import { createContext, useContext } from 'react'

import type { Dispatch, SetStateAction } from 'react'

type Context = Dispatch<SetStateAction<boolean>>
export const FocusContext = createContext<Context | null>(null)

export const useFocusContext = () => {
  const currentUserContext = useContext(FocusContext)

  if (!currentUserContext) {
    throw new Error(
      'useCurrentUser has to be used within <CurrentUserContext.Provider>',
    )
  }

  return currentUserContext
}
