import * as Checkbox from '@radix-ui/react-checkbox'
import { CheckIcon } from '@radix-ui/react-icons'

import { OptionWrapper } from './OptionWrapper'

import type { GroupBase, OptionProps } from 'react-select'

export const Option = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  children,
  ...props
}: OptionProps<Option, IsMulti, Group>) => {
  const { isMulti } = props
  return (
    <OptionWrapper {...props}>
      {isMulti && (
        <Checkbox.Root
          checked={props.isSelected}
          className={`
            tw-flex tw-h-[15px] tw-w-[15px] tw-shrink-0 tw-appearance-none
            tw-items-center tw-justify-center tw-border tw-border-solid
            tw-border-primary-lightgrey tw-bg-white tw-outline-none

            data-[state=checked]:tw-border-primary-blue

            hover:tw-border-primary-blue
          `}
          disabled={props.isDisabled}
        >
          <Checkbox.Indicator className="tw-text-primary-blue">
            <CheckIcon />
          </Checkbox.Indicator>
        </Checkbox.Root>
      )}
    </OptionWrapper>
  )
}
