import { RefObject, useEffect, useState } from 'react'
import { GroupBase, Props, SelectInstance } from 'react-select'

export type FocusSharedProps = {
  readonly onClickOutside?: () => void
}

export function useFocusManager<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  onClickOutside,
  ref,
  onMenuClose,
}: {
  ref: RefObject<SelectInstance<Option, IsMulti, Group>>
} & FocusSharedProps &
  Pick<Props<Option, IsMulti, Group>, 'onMenuClose'>) {
  const [isFocused, setIsFocused] = useState(false)

  const onDomClick = (e: MouseEvent | TouchEvent) => {
    const target = e.target as Node
    let menu = ref.current?.controlRef?.nextSibling
    const container = menu?.parentNode
    if (!container?.contains(target) && (!menu || !menu.contains(target))) {
      onClickOutside && onClickOutside()
      onMenuClose && onMenuClose()
      setIsFocused(false)
    } else if (
      container?.contains(target) &&
      (!menu || !menu.contains(target))
    ) {
      // @ts-ignore
      document.activeElement?.blur()
      setTimeout(() => setIsFocused(false), 100)
    }
  }

  useEffect(() => {
    if (isFocused) {
      document.addEventListener('touchstart', onDomClick)
      document.addEventListener('mousedown', onDomClick)

      return () => {
        document.removeEventListener('mousedown', onDomClick)
        document.removeEventListener('touchstart', onDomClick)
      }
    }
  }, [isFocused])

  return {
    props: {
      openMenuOnFocus: !isFocused,
      openMenuOnClick: !isFocused,
      menuIsOpen: isFocused || undefined,
    },
    setIsFocused,
  }
}
