import { ItemRenderer } from './ItemRenderer'
import { OverflowList as Vendor } from 'react-overflow-list'
import { ComponentProps } from 'react'
import classNames from 'classnames'
import { SetOptional } from 'type-fest'
import { CurrentOption } from '../OverflowMultiSelect/components/MultiSelect'
import { OverflowListPopover } from './OverflowListPopover'

type Req = Pick<
  ComponentProps<typeof Vendor<CurrentOption>>,
  'className' | 'itemRenderer' | 'overflowRenderer' | 'items'
>
type Props = SetOptional<Req, 'itemRenderer' | 'overflowRenderer'>
export function OverflowList({ className, ...rest }: Props) {
  return (
    <Vendor
      {...rest}
      className={classNames('tw-space-x-2', className)}
      collapseFrom="end"
      minVisibleItems={1}
      itemRenderer={ItemRenderer}
      overflowRenderer={OverflowListPopover}
    />
  )
}
