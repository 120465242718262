import { components } from 'react-select'

import { TooltipPrimitives } from '@components/Tooltip'

import type { PlaceholderProps, GroupBase } from 'react-select'

export function Placeholder<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ children, ...props }: PlaceholderProps<Option, IsMulti, Group>) {
  return (
    <components.Placeholder {...props}>
      <TooltipPrimitives.Provider>
        <TooltipPrimitives.Root>
          <TooltipPrimitives.Trigger
            className="tw-cursor-not-allowed"
            style={{ pointerEvents: 'all' }}
            type="button"
          >
            {children}
          </TooltipPrimitives.Trigger>
          <TooltipPrimitives.Content side="right">
            {props.selectProps.tooltip}
          </TooltipPrimitives.Content>
        </TooltipPrimitives.Root>
      </TooltipPrimitives.Provider>
    </components.Placeholder>
  )
}
