import { useEffect, useRef } from 'react'
import { components } from 'react-select'

import { useFocusContext } from '@components/Form/components/Select/hooks/use-focus-context'

import type { GroupBase, MenuProps } from 'react-select'

export function MenuWithFilter<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ children, ...props }: MenuProps<Option, IsMulti, Group>) {
  const setIsFocused = useFocusContext()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!props.selectProps.isMulti) {
      return
    }
    const cancel = setTimeout(() => {
      inputRef.current?.focus()
    }, 800)
    return () => {
      clearTimeout(cancel)
    }
  }, [props.selectProps.menuIsOpen])

  return (
    <components.Menu {...props} className="tw-z-20 tw-min-w-[300px]">
      <div
        className={`
        tw-relative tw-border-b tw-border-solid
        tw-border-b-primary-table-border-color tw-p-5
      `}
      >
        <div
          className={`
          tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-0 tw-flex
          tw-items-center tw-pl-7
        `}
        >
          <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M7.16883 1.03896C3.7834 1.03896 1.03896 3.7834 1.03896 7.16883C1.03896 10.5543 3.7834 13.2987 7.16883 13.2987C8.82988 13.2987 10.3366 12.638 11.4406 11.5651C11.4574 11.5414 11.4764 11.5188 11.4976 11.4976C11.5188 11.4764 11.5414 11.4574 11.5651 11.4406C12.638 10.3366 13.2987 8.82988 13.2987 7.16883C13.2987 3.7834 10.5543 1.03896 7.16883 1.03896ZM12.592 11.8574C13.6797 10.6004 14.3377 8.96148 14.3377 7.16883C14.3377 3.20959 11.1281 0 7.16883 0C3.20959 0 0 3.20959 0 7.16883C0 11.1281 3.20959 14.3377 7.16883 14.3377C8.96148 14.3377 10.6004 13.6797 11.8574 12.592L15.1132 15.8478C15.3161 16.0507 15.645 16.0507 15.8478 15.8478C16.0507 15.645 16.0507 15.3161 15.8478 15.1132L12.592 11.8574Z"
              fill="#ACB1B5"
              fillRule="evenodd"
            />
          </svg>
        </div>
        <input
          autoComplete="off"
          autoCorrect="off"
          className={`
            tw-block tw-w-full tw-rounded tw-border-0 tw-py-1.5 tw-pl-10
            tw-text-gray-900 tw-ring-1 tw-ring-inset
            tw-ring-primary-table-border-color

            focus:tw-ring-2 focus:tw-ring-inset

            placeholder:tw-text-gray-400

            sm:tw-text-sm sm:tw-leading-6
          `}
          onChange={(e) => {
            props.selectProps.onInputChange(e.target.value, {
              action: 'input-change',
              prevInputValue: 'wha',
            })
          }}
          onFocus={() => {
            setIsFocused(true)
          }}
          onMouseDown={(e) => {
            e.stopPropagation()
            ;(e.target as HTMLInputElement).focus()
          }}
          onTouchEnd={(e) => {
            e.stopPropagation()
            ;(e.target as HTMLInputElement).focus()
          }}
          placeholder={
            props.selectProps.inputPlaceholder ||
            Translator.trans('filter_by.filter-items')
          }
          ref={inputRef}
          spellCheck="false"
          type="text"
          value={props.selectProps.inputValue}
        />
      </div>
      {children}
    </components.Menu>
  )
}
