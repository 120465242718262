import GenericListItem from '../OverflowMultiSelect/components/GenericListItem'
import classNames from 'classnames'
import { isOptionWithBackgroundColor, OptionUnion } from '../OverflowMultiSelect/components/MultiSelect'
import { Key } from 'react'

const ContentWrapper: typeof GenericListItem.ContentWrapper = (props) =>
  GenericListItem.ContentWrapper({ ...props, className: 'tw-truncate' })

export const ItemRenderer = (item: OptionUnion, index: Key) => {
  return (
    <GenericListItem.GenericListItem
      className={classNames('last-of-type:tw-truncate  tw-whitespace-nowrap tw-flex-nowrap ', {
        "after:tw-content-[','] last-of-type:after:tw-content-none first-of-type:after:tw-content-[',']":
          !isOptionWithBackgroundColor(item),
      })}
      item={item}
      key={index}
      show={{ subtitle: false }}
      components={{
        ContentWrapper,
      }}
    />
  )
}
