import { RefObject, useEffect, useState } from 'react'
const breakPoint = 150
export function useAutoCompact(labelRef: RefObject<HTMLSpanElement>) {
  const [autoCompact, setAutoCompact] = useState(false)
  useEffect(() => {
    const labelWidth = labelRef?.current?.offsetWidth
    const parentWidth = labelRef?.current?.parentElement?.offsetWidth

    if (!labelWidth || !parentWidth) {
      return
    }

    if (parentWidth - labelWidth < breakPoint) {
      setAutoCompact(true)
    }
  }, [])

  return { autoCompact }
}
