import React from 'react'
import { components } from 'react-select'

import { ListItem } from './GenericListItem'

import type { GroupBase, SingleValueProps } from 'react-select'

export function SingleValue<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({ children, ...props }: SingleValueProps<Option, IsMulti, Group>) {
  return (
    <components.SingleValue {...props}>
      <ListItem label={`${children}`} />
    </components.SingleValue>
  )
}
